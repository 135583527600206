import * as Sentry from '@sentry/nextjs';
import {
  ReportMoveInResponse,
  SingleReviewResponse,
} from '@services/review/api';
import { generateGeosLinks } from '@utils/interlinking';
import { checkIfIsASeniorHomesDomain } from '@utils/isSeniorHomesDomain';
import { stringify } from 'qs';
import { ParsedUrlQuery } from 'querystring';
import { Provider } from '~/contexts/Provider';
import { APIDOMAINS, Domain, Domains } from '~/types/Domains';
import { Source } from '~/types/json-api-response';
import { Page } from '~/types/Magnolia';
import { ProviderReview, ReviewRawData } from '~/types/reviews';
import type { GeoPageData } from '../magnolia/getCatalog.type';
import { locationResponseAdapter } from './adapters/locationResponseAdapter';
import { reviewResponseAdapter } from './adapters/reviewResponseAdapter';
import { videoIframeResponseAdapter } from './adapters/videoIframeResponseAdapter';
import { ModularMonolithErrorResponse } from './types/common.type';
import {
  CostComparisionByLocaleResponse,
  GetCostsByCareTypeQuery,
  GetCostsByLocaleQuery,
  GetCostsByYearByCareTypeQuery,
  GetParameterizedLanguageCostsQuery,
  ParameterizedLanguageCosts,
} from './types/costs.type';
import {
  APISendInquiryParams,
  APISendInquiryResponse,
  ModMonAddInquiryResponse,
} from './types/inquiry.type';
import { GetGeosFilters, GetGeosResponse } from './types/interlinking.type';
import { LeadsResponse } from './types/leads.type';
import { LocationResponse } from './types/location.type';
import {
  EnhancedSearchResponse,
  FacetedSearchAPIResponse,
  FacetedSearchRequestQuery,
  FacetedSearchResponse,
  GetNearbyProvidersFilters,
  GetProvidersFilters,
  GetProvidersResponse,
  NearbySearchResponse,
} from './types/search.type';
import {
  LineChartItemData,
  BarChartItemData,
} from '@components/CostModule/types';
const DEFAULT_DOMAIN = Domains.CaringDomains.LIVE;
const baseUrl = process.env.NEXT_PUBLIC_CANARIO_HOST;

export class ModularMonolithClient {
  private domain: Domain;
  private source: Source;

  constructor(domain?: Domain) {
    this.domain = domain || DEFAULT_DOMAIN;
    this.source =
      this.domain === Domains.CaringDomains.LIVE
        ? Source.LEGACY
        : Source.ALGOLIA;
  }

  getVariant = (prefix: string = '?', rollUpType?: string): string => {
    const currentDomain: Domain = this.domain;

    const isASeniorHomesDomain = checkIfIsASeniorHomesDomain(currentDomain);

    if (isASeniorHomesDomain) {
      return `${prefix}variant=seniorhomes`;
    }

    const isACaringDomain = currentDomain === Domains.CaringDomains.LIVE;
    if (isACaringDomain && rollUpType === 'senior-care') {
      return `${prefix}careType=seniorcare`;
    }
    return '';
  };

  getLocation = async (
    slug: string,
    rollUpType?: string
  ): Promise<Provider | null> => {
    try {
      if (slug === 'settings') return null;

      const response = await fetch(
        `${baseUrl}/v2/location/${slug}${this.getVariant('?', rollUpType)}`
      );

      if (response.status === 200) {
        const locationResponse: LocationResponse = await response.json();
        if (locationResponse.description) {
          const locationDescription = await videoIframeResponseAdapter(
            locationResponse.description
          );
          locationResponse.description = locationDescription;
        }

        return locationResponseAdapter(locationResponse);
      }

      return null;
    } catch (error) {
      return null;
    }
  };

  getLocationByUrl = async (
    url: string,
    website: APIDOMAINS
  ): Promise<Provider | null> => {
    const response = await fetch(
      `${baseUrl}/v3/location${url}?website=${website}`
    );
    const locationResponse: LocationResponse = await response.json();
    return locationResponseAdapter(locationResponse);
  };

  getTopReviews = async ({
    careType,
    city,
    state,
    county,
    limit = '5',
  }: {
    careType?: string;
    city?: string;
    state?: string;
    county?: string;
    limit: string;
  }): Promise<ProviderReview[] | null> => {
    try {
      if (!city && !state && !county && !careType) {
        return null;
      }

      const params = new URLSearchParams({
        careType: careType || '',
        city: city || '',
        state: state || '',
        county: county || '',
        count: limit,
      });
      const response = await fetch(
        `${baseUrl}/v1/location/reviews/by-locale/?${params}${this.getVariant(
          '&'
        )}`
      );
      const locationResponse: ReviewRawData[] = await response.json();

      if (response.status === 200) {
        return reviewResponseAdapter({
          data: locationResponse,
          city,
          state,
          domain: this.domain,
        });
      }

      return null;
    } catch (error) {
      console.error('getTopReviews: Error getting data.', error);
      return null;
    }
  };

  getSiteMap = async (state: string): Promise<string[]> => {
    try {
      const response = await fetch(
        `${baseUrl}/v1/site-map/${state}${this.getVariant()}`
      );
      const siteMapResponse: string[] = await response.json();

      if (response.status === 200) {
        return siteMapResponse;
      }

      return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  sendInquiryForm = async ({
    partnerToken,
    body,
  }: APISendInquiryParams): Promise<APISendInquiryResponse> => {
    try {
      const { provider_id = undefined } = body;

      /**
       * This rule was defined here:
       * https://ableco.slack.com/archives/C05128G4DUZ/p1680202187112639?thread_ts=1680201981.418299&cid=C05128G4DUZ
       */
      let modMonEndpoint = `${baseUrl}/v3/partner/inquiry`;
      if (provider_id) modMonEndpoint = `${baseUrl}/v3/partner/lead`;

      const response = await fetch(modMonEndpoint, {
        method: 'POST',
        headers: {
          'Caring-Partner': partnerToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const modMonResponse: ModMonAddInquiryResponse = await response.json();

      if (modMonResponse?.result) {
        return {
          success: true,
          message: 'Ok',
          provider: modMonResponse.provider,
        };
      }
      Sentry.captureException(
        `Inquiry modmon err: ${JSON.stringify(modMonResponse?.error_message)}`
      );
      return {
        success: false,
        message: `${modMonResponse?.error_code || '500'} - ${
          modMonResponse?.error_message
            ? JSON.stringify(modMonResponse?.error_message)
            : 'Server Error'
        }`,
      };
    } catch (error: unknown) {
      Sentry.captureException(`Inquiry catch err: ${String(error)}`);
      return {
        success: false,
        message: String(error),
      };
    }
  };

  searchProvidersByFacets = async (
    filters: FacetedSearchRequestQuery
  ): Promise<FacetedSearchResponse> => {
    try {
      const params = stringify({ ...filters, domain: this.domain });
      const response = await fetch(`${baseUrl}/v1/faceted-search?${params}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('searchProvidersByFacets: Server error.');
      }

      const providersResponse: FacetedSearchAPIResponse = await response.json();
      return providersResponse.data;
    } catch (error) {
      console.error('searchProvidersByFacets: Error getting data.', error);
      return {
        listId: '',
        queryId: '',
        results: [],
        totalItems: 0,
        totalNearbyItems: 0,
        totalPages: 0,
        totalRegionItems: 0,
      };
    }
  };

  searchProvidersByFacetsV2 = async (
    filters: FacetedSearchRequestQuery
  ): Promise<FacetedSearchResponse> => {
    try {
      const params = stringify({ ...filters, domain: this.domain });
      const response = await fetch(`${baseUrl}/v2/faceted-search?${params}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('searchProvidersByFacetsV2: Server error.');
      }

      const providersResponse: FacetedSearchAPIResponse = await response.json();
      return providersResponse.data;
    } catch (error) {
      console.error('searchProvidersByFacetsV2: Error getting data.', error);
      return {
        listId: '',
        queryId: '',
        results: [],
        totalItems: 0,
        totalNearbyItems: 0,
        totalPages: 0,
        totalRegionItems: 0,
      };
    }
  };

  getProviders = async (
    filters: GetProvidersFilters,
    options?: {
      apiVersion?: string;
    }
  ): Promise<GetProvidersResponse> => {
    try {
      // This ensures that we only use the legacy API for Caring.com
      const source =
        this.domain === Domains.CaringDomains.LIVE
          ? filters.source || this.source
          : Source.ALGOLIA;
      const version = options?.apiVersion === 'v2' ? 'v2' : 'v1';
      const params = stringify({ ...filters, source, domain: this.domain });
      const response = await fetch(
        `${baseUrl}/${version}/enhanced-search?${params}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error('getProviders: Server error.');
      }

      const providersResponse: EnhancedSearchResponse = await response.json();
      return providersResponse.data;
    } catch (error) {
      console.error('getProviders: Error getting data.', error);
      return {
        listId: '',
        queryId: '',
        totalRegionItems: 0,
        totalNearbyItems: 0,
        totalItems: 0,
        totalPages: 0,
        results: [],
      };
    }
  };

  getNearbyProviders = async (
    filters: GetNearbyProvidersFilters
  ): Promise<GetProvidersResponse> => {
    try {
      const params = stringify({ ...filters, domain: this.domain });
      const response = await fetch(`${baseUrl}/v1/nearby-search?${params}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('getNearbyProviders: Server error.');
      }

      const providersResponse: NearbySearchResponse = await response.json();
      return {
        totalRegionItems: 0,
        ...providersResponse.data,
      };
    } catch (error) {
      console.error('getNearbyProviders: Error getting data.', error);
      return {
        listId: '',
        queryId: '',
        totalRegionItems: 0,
        totalNearbyItems: 0,
        totalItems: 0,
        totalPages: 0,
        results: [],
      };
    }
  };

  getGeosByCareType = async (
    filters: GetGeosFilters
  ): Promise<GetGeosResponse> => {
    try {
      const { alteredCareType } = filters;
      const queryParams = new URLSearchParams(
        Object.entries(filters).filter(
          ([_, value]) => value !== undefined
        ) as any
      ).toString();

      const response = await fetch(`${baseUrl}/v1/dxp/cities?${queryParams}`);

      if (!response.ok) {
        throw new Error('getCities: Server error.');
      }

      const citiesResponse: GetGeosResponse = await response.json();
      citiesResponse.items = citiesResponse.items.map((item) => {
        const name = item.name;
        const slug =
          filters.listType === 'cities' ? item.cityURLName : item.slug;
        return {
          ...item,
          name,
          link: generateGeosLinks(alteredCareType, item.stateURLName, slug),
        };
      });
      return citiesResponse;
    } catch (error) {
      console.error('getCities: Error getting data.', error);
      return {
        total: 0,
        page: 1,
        nextPage: null,
        pageSize: 0,
        items: [],
      };
    }
  };

  getReviewByToken = async (token): Promise<SingleReviewResponse | null> => {
    try {
      if (!token) return null;

      const response = await fetch(`${baseUrl}/v1/dxp/reviews?token=${token}`);

      const singleReviewResponse: SingleReviewResponse = await response.json();

      if (response.status === 200) {
        return singleReviewResponse;
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  getReportMoveInLocations = async (
    token
  ): Promise<ReportMoveInResponse | null> => {
    try {
      const response = await fetch(`${baseUrl}/v1/dxp/move-in?token=${token}`);
      const parsed: ReportMoveInResponse = await response.json();

      if (response.status === 200) {
        return parsed;
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  postReportMoveIn = async (
    payload
  ): Promise<{
    success: boolean;
    message?: string;
    error?: string;
  }> => {
    try {
      const response = await fetch(`${baseUrl}/v1/dxp/move-in`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        return { success: true };
      }

      return {
        success: false,
        error: 'An error occurred while processing the request',
      };
    } catch (error) {
      return {
        success: false,
        error: 'An error occurred while processing the request',
      };
    }
  };

  postReviewResponse = async (
    token,
    payload
  ): Promise<{
    success: boolean;
    data?: SingleReviewResponse;
    error?: string;
  }> => {
    try {
      const response = await fetch(
        `${baseUrl}/v1/dxp/reviews/response?token=${token}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        return { success: true };
      }

      const reviewResponse = await response.json();

      return {
        success: false,
        error: reviewResponse.message,
      };
    } catch (error) {
      return {
        success: false,
        error: 'An error occurred while processing the request',
      };
    }
  };

  postReviewContest = async (
    token,
    payload
  ): Promise<{
    success: boolean;
    data?: SingleReviewResponse;
    error?: string;
  }> => {
    try {
      const response = await fetch(
        `${baseUrl}/v1/dxp/reviews/contest?token=${token}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        return { success: true };
      }

      const reviewResponse = await response.json();

      return {
        success: false,
        error: reviewResponse.message,
      };
    } catch (error) {
      return {
        success: false,
        error: 'An error occurred while processing the request',
      };
    }
  };

  postConfirmDuplicatedReview = async (
    token: string
  ): Promise<{ success: boolean; error?: string }> => {
    return await this.postConfirmOrDenyDuplicatedReview(
      token,
      `${baseUrl}/v1/dxp/reviews/review/duplicate/approve/`
    );
  };

  postDenyDuplicatedReview = async (
    token: string
  ): Promise<{ success: boolean; error?: string }> => {
    return await this.postConfirmOrDenyDuplicatedReview(
      token,
      `${baseUrl}/v1/dxp/reviews/review/duplicate/deny/`
    );
  };

  private postConfirmOrDenyDuplicatedReview = async (
    token: string,
    url: string
  ): Promise<{ success: boolean; error?: string }> => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ confirmationToken: token }),
      });

      if (response.ok) {
        return { success: true };
      }

      const requestResponse = await response.json();

      return {
        success: false,
        error:
          requestResponse.error ||
          requestResponse.message ||
          'An error occurred while processing the request',
      };
    } catch (error) {
      return {
        success: false,
        error: 'An error occurred while processing the request',
      };
    }
  };

  getGeoPageData = async (
    path: string,
    params?: ParsedUrlQuery
  ): Promise<GeoPageData | null> => {
    try {
      const apiVersion = params?.geo === 'v2' ? 'v2' : 'v1';
      const sanitizedPath = path.startsWith('/') ? path.slice(1) : path;
      const response = await fetch(
        `${baseUrl}/${apiVersion}/geo-pages/${sanitizedPath}`
      );
      const geoPageData = await response.json();
      return geoPageData?.data || null;
    } catch (error) {
      console.error('getGeoPageData: Error getting data.', error);
      return null;
    }
  };

  getReviewRedirect = async (id: string): Promise<{ destination: string }> => {
    const response = await fetch(`${baseUrl}/v1/dxp/reviews/legacy?id=${id}`);
    return response.json();
  };

  getRecentReviews = async ({
    careType,
    city,
    county,
    state,
    limit,
    domain,
    apiVersion,
  }: {
    careType: string;
    city?: string;
    county?: string;
    state?: string;
    limit: number;
    domain: Domain;
    apiVersion?: string;
  }) => {
    try {
      const queryParams = new URLSearchParams(
        Object.entries({
          careType,
          city,
          county,
          state,
          limit,
          domain,
        }).filter(([_, value]) => value !== undefined) as string[][]
      ).toString();

      const version = apiVersion === 'v2' ? 'v2' : 'v1';
      const response = await fetch(
        `${baseUrl}/${version}/dxp/location-reviews/recent?${queryParams}`
      );
      if (!response.ok) {
        const responseError: ModularMonolithErrorResponse =
          await response.json();
        console.error(`getRecentReviews: ${responseError.message}`);
        return null;
      }
      return await response.json();
    } catch (error) {
      console.error('getRecentReviews: Error getting data.', error);
      return null;
    }
  };

  getLeads = async (
    encryptedCareSeekerId: string
  ): Promise<LeadsResponse | null> => {
    try {
      const response = await fetch(
        `${baseUrl}/v1/b2c/care-recipient-leads/q/${encryptedCareSeekerId}`
      );

      if (!response.ok) {
        throw new Error('Failed to fetch leads');
      }

      return response.json();
    } catch (error) {
      console.error('fetchLeads: Error getting data.', error);
      return null;
    }
  };

  getPage = async (pagePath: string): Promise<Page | null> => {
    return this.fetchResponseJSON<Page>(
      `${baseUrl}/v1/magnolia/pages?path=${pagePath}`,
      // We use this route to test if a path exists in Magnolia or not, so
      // we need to handle errors like a 404 as if they were expected results,
      // not errors
      { acceptedStatus: [404] }
    );
  };

  private fetchResponseJSON = async <SuccessResponse = any>(
    api: string | URL,
    options: {
      acceptedStatus: number[];
    } = {
      acceptedStatus: [],
    }
  ): Promise<Awaited<SuccessResponse> | null> => {
    try {
      const response = await fetch(api);

      if (response.ok) {
        return await response.json();
      }

      if (!options.acceptedStatus.includes(response.status)) {
        throw new Error();
      }

      return null;
    } catch (error) {
      console.error(`Error getting data from ${api}`, error);
      return null;
    }
  };

  getCostsByCareType = async ({
    state,
    county,
    moveInThreshold,
    geoType,
  }: GetCostsByCareTypeQuery): Promise<
    CostComparisionByLocaleResponse[] | undefined
  > => {
    try {
      const response = await fetch(
        `${baseUrl}/v1/costs/summary?type=careType&state=${state}&county=${county}&geoType=${geoType}&moveInThreshold=${moveInThreshold}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      return response.json();
    } catch (error) {
      console.error('getCostsByCareType: Error getting data.', error);
      return undefined;
    }
  };

  getCostsByLocale = async ({
    state,
    county,
    serviceCategoryId,
    geoType,
    moveInThreshold,
  }: GetCostsByLocaleQuery): Promise<BarChartItemData[] | undefined> => {
    try {
      const response = await fetch(
        `${baseUrl}/v1/costs/summary?type=locale&state=${state}&county=${county}&serviceCategoryId=${serviceCategoryId}&geoType=${geoType}&moveInThreshold=${moveInThreshold}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      return response.json();
    } catch (error) {
      console.error('getCostsByLocale: Error getting data.', error);
      return undefined;
    }
  };

  getCostsByYearForCareType = async ({
    state,
    county,
    serviceCategoryId,
    moveInThreshold,
  }: GetCostsByYearByCareTypeQuery): Promise<
    LineChartItemData[] | undefined
  > => {
    try {
      const response = await fetch(
        `${baseUrl}/v1/costs/summary?type=yearly&state=${state}&county=${county}&serviceCategoryId=${serviceCategoryId}&moveInThreshold=${moveInThreshold}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      return response.json();
    } catch (error) {
      console.error('getCostsByYearForCareType: Error getting data.', error);
      return undefined;
    }
  };
  getParameterizedLanguageCostData = async ({
    state,
    county,
  }: GetParameterizedLanguageCostsQuery): Promise<
    ParameterizedLanguageCosts | undefined
  > => {
    const alteredCounty =
      county && county.replace(/(County|Parish)/i, '').trim();
    const countyParam = county ? `&county=${alteredCounty}` : '';
    try {
      const response = await fetch(
        `${baseUrl}/v1/costs/summary?type=parameterizedLanguage&state=${state}${countyParam}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      return response.json();
    } catch (error) {
      console.error(
        'getParameterizedLanguageCosts: Error getting data.',
        error
      );
      return undefined;
    }
  };
}
